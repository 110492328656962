import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./src/themes/theme"
import { Helmet } from "react-helmet"

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: ${props => props.theme.font.main};
    }

    html {
        overflow-x: hidden;
    }

    body, html {
        height: 100%;
        background-color: ${props => props.theme.colors.background};
        overscroll-behavior: none;
    }
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0px;
        padding: 0px;
        border: 0px none;
        vertical-align: baseline;
        scroll-behavior: unset;
    }
      
    *, ::before, ::after {
        box-sizing: border-box;
    }

    ::selection {
        background: ${props => props.theme.colors.foreground};
        color: ${props => props.theme.colors.background};
    }

    body {
        width: 100vw;
        min-height: 100vh;
        margin-left: auto;
        margin-right: auto;
        // overflow-x: hidden;
    }
    
    a {
        color: ${props => props.theme.colors.foreground};
        text-decoration: none;
    }
    
    a:hover {
        color: ${props => props.theme.colors.secondary};
        text-decoration: none;
    }

    strong {
        font-weight: 500;
    }
    
    button {
        background: none;
        text-decoration: none;
        border: none;
    }
    
    button:focus {
        outline: none;
        box-shadow: none;
    }

    .rotate {
        display: inline-block;
        animation-name: spinner;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        transform-style: preserve-3d;
    }

    @keyframes spinner {
        from {
          transform: rotateY(0deg);
        }
        to {
          transform: rotateY(-360deg);
        }
    }

    .animpath {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: dash 10s linear infinite;
        }
        
        @keyframes dash {
        to {
            stroke-dashoffset: 0;
        }
    }
    
    .animrect {
        animation: opac 10s linear infinite;
        }
        
        @keyframes opac {
            0% {opacity: 0;}
            30% {opacity: 0;}
            40% {opacity: 1;}
            100% {opacity: 1;}
    }
`

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={Theme}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Chaire PcEn</title>
          <link href="https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200..500;1,200..500&subset=latin-ext&display=swap" rel="stylesheet" />
        </Helmet>
        <GlobalStyles />
        {element}
    </ThemeProvider>
)