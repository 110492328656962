module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chaire Pluralisme culturel et Éthique du numérique","short_name":"Chaire PcEn","start_url":"/","icon":"src/images/arrow_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"670841d49f31df3e5f3e0d1f552b545d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
