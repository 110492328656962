export default {
    font: {
        main: "Work Sans",
    },
    colors: {
        foreground: "#181818",
        background: "#e6e6e6",
        secondary: "#666666",
        gray1: "#8d8d8d",
        gray2: "#b5b5b5",
        gray3: "#d6d6d6",
        gray4: "#e6e6e6",
        darkGrey: "#3b3b3b",
        spaceCadet: "#2A324B",
        rhythm: "#767B91"
    },
    breakpoints: {
        mobile: "only screen and (max-width: 899px)",
        desktop: "only screen and (min-width: 900px)",
    },
    spacings: {
        xxSmall: "5px",
        xSmall: "10px",
        small: "12px",
        medium: "16px",
        large: "21px",
        xLarge: "28px",
        xxLarge: "38px",
        largest: "50px",
        section: "8vw",
    },
    footerExtendBorderTop: ":before {border-top: 1px solid #8d8d8d;position: absolute;width: 5vw !important;margin-left: -5vw;top: -1px;bottom: -1px;content: '';box-sizing: border-box;} :after{border-top: 1px solid #8d8d8d;position: absolute;width: 5vw !important;margin-right: -5vw;right: 0;top: -1px;bottom: -1px;content: '';box-sizing: border-box;}",
    footerExtendFill: ":before {background-color: #181818;position: absolute;width: 5vw !important;margin-left: -5vw;top: 0px;bottom: 0px;content: '';box-sizing: border-box;} :after{background-color: #181818;position: absolute;width: 5vw !important;margin-right: -5vw;right: 0;top: 0px;bottom: 0px;content: '';box-sizing: border-box;}",
    mailExtendFill: ":before {background-color: #666666;position: absolute;width: 5vw !important;margin-left: -5vw;top: 0px;bottom: 0px;content: '';box-sizing: border-box;} :after{background-color: #666666;position: absolute;width: 5vw !important;margin-right: -5vw;right: 0;top: 0px;bottom: 0px;content: '';box-sizing: border-box;}",
    extendBorderTop: ":before {border-top: 1px solid #666666;position: absolute;width: 5vw !important;margin-left: -5vw;margin-top: -1px;content: '';box-sizing: border-box;} :after{border-top: 1px solid #666666;position: absolute;width: 5vw !important;right: 0;margin-top: -1px;content: '';box-sizing: border-box;}",
    extendBorderBottom: ":before {border-bottom: 1px solid #181818;position: absolute;width: 5vw !important;margin-left: -5vw;top: 0px;bottom: 0px;content: '';box-sizing: border-box;} :after{background-color: #181818;position: absolute;width: 5vw !important;margin-right: -5vw;right: 0;top: 0px;bottom: 0px;content: '';box-sizing: border-box;}",
}